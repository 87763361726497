<template>
	<div class="date-select">
		<div
			class="date-select__static"
			@click="toggleDateSelect"
			v-click-outside="closeDateSelect"
			ref="dateSelectStatic"
		>
			<div class="date-select__time">
				<img
					src="@/assets/img/clock.svg"
					alt="Clock"
				/>
				<span class="date-select__option">{{ selectedTime.displayValue || 'Выберите время' }}</span>
			</div>
			<img
				class="date-select__icon"
				:class="{'date-select__icon-toggle' : isToggleDateSelect}"
				src="@/assets/img/arrowDown.svg"
				alt="Arrow Down"
			>
		</div>
		<div
			class="date-select__rows"
			v-if="intervals.length"
			:class="{'date-select__rows-toggle': isToggleDateSelect, 'date-select__rows-position-bottom': position === 'bottom'}"
		>
			<div
				class="date-select__row"
				v-for="option of intervals"
				:key="option.value"
				@click="selectOption(option)"
				:class="{'date-select__row-current' : option.selected }"
			>
				<span class="date-select__option">{{ option.displayValue }}</span>
			</div>
		</div>
		<div
			class="date-select__rows"
			v-else
			:class="{'date-select__rows-toggle': isToggleDateSelect}"
		>
			<span class="date-select__option date-select__option-disabled">Нет доступного времени</span>
		</div>
	</div>
</template>

<script>
// todo: сделать скрытие по click-outside
import {mapGetters} from "vuex";

const DAYS = {
	today: 'Сегодня',
	tomorrow: 'Завтра'
}

export default {
	props: ['options'],
	// дата
	data()
	{
		return {
			isToggleDateSelect: false,
			intervals: [],
			selectedTime: false,
			timer: null,
			position: 'top'
		}
	},
	computed:
		{
			...mapGetters({
				currentDeliveryType: 'order/currentDeliveryType',
				currentDayStr: 'currentDayStr',
				currentCityId: 'currentCityId',
				timeRange: 'getTimeRange',
				currentRestarauntId: 'getRestarauntId',
				currentBasket: 'getCurrentBasket',
			}),
		},
	watch:
		{
			currentDeliveryType()
			{
				this.makeIntervals();
			},
			timeRange()
			{
				this.makeIntervals();
			},
			currentDayStr()
			{
				this.makeIntervals();
			},
		},
	methods:
		{
			isBelowHalfScreen()
			{
				const dateSelectStatic = this.$refs.dateSelectStatic;
				const rect = dateSelectStatic.getBoundingClientRect();
				const windowHeight = (window.innerHeight || document.documentElement.clientHeight) / 2;

				if (rect.top > windowHeight) return this.position = 'bottom';

				this.position = 'top';
			},
			// выбирает время
			selectOption(newOption)
			{
				if (!newOption) return;

				for (let option of this.intervals)
				{
					option.selected = false;
				}
				newOption.selected = true;


				this.selectedTime = newOption;
				this.$emit('change', newOption);
				this.isToggleDateSelect = false;
			},
			toggleDateSelect()
			{
				if (!this.isToggleDateSelect) this.isBelowHalfScreen();

				this.isToggleDateSelect = !this.isToggleDateSelect;
			},
			closeDateSelect()
			{
				this.isToggleDateSelect = false;
			},
			async getWorkTimes()
			{
				try
				{
					const {data} = await this.$axios.get(
						'/cart/getWorkTimes',
						{
							params: {
								cityId: this.currentCityId,
								deliveryType: this.currentDeliveryType,
								day: this.currentDayStr,
								timeRange: this.timeRange,
								restId: this.currentRestarauntId
							}
						}
					)

					if (!data.success) return;

					return data.times;
				} catch (e)
				{
					throw new Error(e);
				}
			},
			async makeIntervals()
			{
				const wortTimes = await this.getWorkTimes();

				if (!wortTimes.length)
				{
					this.intervals = [];
					this.selectedTime = false;
					return;
				}

				const times = wortTimes.reduce((acc, time, index) =>
				{
					const endTime = wortTimes[index + 1];

					if (endTime)
					{
						acc.push({
							startTime: time,
							value: `${time} ${endTime ? '- ' + endTime : ''}`,
							displayValue: `${DAYS[this.currentDayStr]} с ${time} ${endTime ? '- ' + endTime : ''}`,
							selected: false
						});
					}

					return acc;
				}, []);

				if (this.currentBasket?.plannedDeliveryTime) {
					const time = times.find(({ value }) => value === this.currentBasket?.plannedDeliveryTime)
					this.selectOption(time || times[0]);
				} else {
					this.selectOption(times[0]);
				}

				this.$set(this, 'intervals', times);
			},
			async updateTimes()
			{
				const wortTimes = await this.getWorkTimes();

				if (!wortTimes.length)
				{
					this.intervals = [];
					this.selectedTime = false;
					return;
				}

				let removeCheckedTime = false;
				let selectedValue = this.selectedTime.value;

				this.$set(this, 'intervals', this.intervals.filter(item =>
				{
					let hasInNewArr = wortTimes.indexOf(item.startTime) !== -1;

					if (selectedValue == item.value && !hasInNewArr)
						removeCheckedTime = true;

					return hasInNewArr;
				}));

				if (removeCheckedTime !== false) this.selectOption(this.intervals[0]);
			},
		},
	async mounted()
	{
		this.isBelowHalfScreen();
		await this.makeIntervals();

		this.timer = setInterval(this.updateTimes, 1000 * 60 * 3);
	},
	beforeDestroy()
	{
		clearInterval(this.timer);
	},
}
</script>

<style lang="scss">
.date-select
{
	max-width: 255px;
	background-color: $greyBackground;
	font-family: $mainFont;
	color: $textColor;
	border: 1px solid $greyDelimiter;
	cursor: pointer;
	border-radius: 6px;
	height: fit-content;
	position: relative;
	user-select: none;
}

.date-select__static
{
	font-family: $mainFontMedium;
	font-size: 16px;
	padding: 16px;
	display: flex;
	position: relative;
	line-height: 20px;
	display: flex;
	justify-content: space-between;
}

.date-select__time
{
	display: flex;
	align-items: center;
	gap: 12px;
}

.date-select__icon
{
	width: 24px;
	height: 24px;
	cursor: pointer;
	@include ease(.125s);

	&-toggle
	{
		transform: rotate(180deg)
	}
}

.date-select__option
{white-space: nowrap;}

.date-select__option-disabled
{padding: 7px 20px;}

.date-select__rows
{
	box-sizing: border-box;
	position: absolute;
	z-index: 1;
	width: 100%;
	max-height: 150px;
	left: 0;
	top: calc(100% - 2px);
	padding: 0 0 9px;
	border: 1px solid $greyDelimiter;
	font-family: $mainFontMedium;
	font-size: 16px;
	line-height: 18px;
	color: $textColor;
	background-color: $greyBackground;
	border-radius: 0px 0px 6px 6px;
	border-top-color: transparent;
	opacity: 0;
	visibility: hidden;
	overflow-y: scroll;
	@include ease(.125s);

	&-toggle
	{
		opacity: 1;
		visibility: visible;
	}

	&::-webkit-scrollbar
	{
		width: 0;
	}
}

.date-select__rows-position-bottom
{
	top: auto;
	bottom: calc(100% - 2px);
	border-radius: 6px 6px 0 0;
	padding: 9px 0 0;
	border-top-color: inherit;
	border-bottom-color: transparent;
}

.date-select__row
{
	padding: 7px 20px;

	&:hover
	{
		background-color: #f4f4f4;
	}

	&-current
	{
		background-color: rgba($green, .2);
	}
}
</style>
